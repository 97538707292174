/**
 *
 * Script: Navigation
 *
 * Adapted for WP from Tania Rascia's Responsive Dropdown Navigation Bar
 * https://www.taniarascia.com/responsive-dropdown-navigation-bar/
 *
 * @since 1.0.0
 * @package Leisy
 */

(function($) {
	$(function() {

		//add a dropdown arrow to li items that have a submenu
		$(".primary-navigation .menu-item-has-children").append('<span class="dropdown-arrow"> <i class="far fa-angle-down"></i> </span>');

		// If a link has a dropdown, add sub menu toggle.
		$(".primary-navigation .dropdown-arrow").click(function(e) {
			$(".primary-navigation nav ul li a:not(:only-child)").siblings(".sub-menu").toggle();

			// Close one dropdown when selecting another
			$(".primary-navigation .sub-menu").not($(this).siblings()).hide();
			e.stopPropagation();
		});

		// Clicking away from dropdown will remove the dropdown class
		$("html").click(function() {
			$(".primary-navigation .sub-menu").hide();
		});

		// Toggle open and close nav styles on click
		$(".primary-navigation #nav-toggle").click(function() {
			$('.primary-navigation nav ul').slideToggle();
		});

		// Hamburger to X toggle
		$(".primary-navigation #nav-toggle").on("click", function() {
			this.classList.toggle("active");

			//prevent mobile screens from not scrolling full menu when longer than viewport
			$("html, body").toggleClass("open-menu");
		});
	});
})(jQuery);
